html, body, #root {
    height: 100%;
}

.content {
  min-height: 100%;
}

/*MASONRY GALLERY*/
.masonry > div {
    display: flex;
    justify-content: center;
}

.masonry > div > img {
    width: 100%;
    border-radius: 2px;
}

.masonry {
    display: grid;
    grid-auto-flow: dense;
}

.img-container:hover p {
    display: block;
}

.masonry-home > div > img{
    border-radius: 0;
}
@media screen and (min-width: 768px) {
    .masonry .img-container:hover img {
        opacity: 0.6;
    }
}

/*LIGHTBOX*/

#lightbox {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
}

#lightbox.active {
    display: flex;
    align-content: center;
    justify-content: center;
}

#lightbox.active img {
    margin-top: 80px;
    max-width:85%;
    max-height: 85%;
}

svg {
    fill: rgba(0, 0, 0, 0.5);
}


@media screen and (max-width: 768px) {
    #lightbox.active img {
        margin-top: 190px;
        max-width:90%;
        max-height: 65%;
    }
}

@media screen and (max-width: 470px) {
    #lightbox.active img {
        margin-top: 240px;
        max-width:90%;
        max-height: 50%;
    }
}

/*navlinks*/

li a {
    font-weight: lighter;
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 0 1px;
    transition: background-size 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
    background-image: linear-gradient(#282828, #282828);
}

li a:hover {
    font-weight: bolder;
    background-size: 100% 1px;
}

a:hover  svg {
    fill: black;
}

li a.active {
    font-weight: bolder;
}

/*FOOTER*/
footer {
    position: sticky;
    top: 100vh;
}

/*LINK*/
.inline-link {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 100% 1px;
    transition: background-size 350ms cubic-bezier(0.645, 0.045, 0.355, 1);
    background-image: linear-gradient(#282828, #282828);
}

.inline-link:hover {
    background-size: 0 1px;
}